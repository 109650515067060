import useCommissionFormatService from "@/services/use-commission-format-service";
import useCommissionQueryService from "@/services/use-commission-query-service";
import usePerformanceBasedPayQueryService from "@/services/use-performance-based-pay-query-service";
import usePayrollService from "@/services/use-payroll-service";
import useCommissionService from "@/services/use-commission-service";
import useInsuranceCompanyService from "@/services/use-insurance-company-service";
import useSecuritiesQueryService from "@/services/use-securities-query-service";
import useContractReportQueryService from "@/services/use-contract-reports-service";
import { defineStore } from "pinia";
import { ref } from "vue";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import {
  getValueFromEnum,
  removeUndefinedAndEmptyStringFromObject,
} from "@/utils/helper";
import type {
  CommissionIngestionStatusScreenItem,
  SelectedCommissionType,
  SelectedContractReportType,
} from "@/types/CommissionAndPerformanceBasedPayCalculation/commission-and-performance-based-pay-calculation-types";
import type {
  GetCommissionFormat200Response,
  CommissionCategory,
  GetCommissions200Response,
  GetContractReportsCommissionMatching200ResponseResultsInner,
  PerformanceBasedPayResult,
  GetCommissionInsuranceCompanyCalculationResults200Response,
  GetCommissionCalculationResults200ResponseResultsInner,
} from "@/api";
import { mapFalsyToUndefinedAndCall } from "@/utils/helper";
import _ from "lodash";
import type { CommissionCalculationResultTable } from "@/types/CommissionAndPerformanceBasedPayCalculationManagement/commission-and-performance-based-pay-calculation-types";

const args = [];
// Inject configuration for each stage(development, staging, production)
const { configObject } = useEnvironmentVars();
args.push(configObject);

// const isDataExists = (value: any): value is Response => {
//   return value !== null;
// };

// =================================================================================================
// Import functions from services
// =================================================================================================

const { preCalculationCheck, calculate, exportBy, findBy } =
  usePerformanceBasedPayQueryService();
const {
  getCurrentMonth,
  getStatus,
  calculate: calcCommission,
  getCommissionsQuery,
  getCommissionInsuranceCompanyCalculationResults,
  getCommissionCalculationResults,
  exportCommissionCalculationResultsBy,
} = useCommissionQueryService();
const { postSecuritiesCommissionMatching, importFile: importSecuritiesCsv } =
  useSecuritiesQueryService();
const { delete: deleteCommission, importFile: importCommissionCsv } =
  useCommissionService();
const { importFile: importPayrollCsv } = usePayrollService();
const { get, update } = useCommissionFormatService();
const { get: getInsuranceCompany } = useInsuranceCompanyService();
const { findMatch } = useContractReportQueryService();

// =================================================================================================
// Define store
// =================================================================================================

export const useCommissionAndPerformanceBasedPayCalculationStore = defineStore(
  "commission-and-performance-based-pay-calculation",
  () => {
    // Use to display no data found message
    const noDataFound = ref(false);
    const noCommissionCalculationResultsFound = ref(false);

    // flag for loading indicator
    const sendingRequest = ref(false);
    const contractReportMatchesSendingRequest = ref(false);

    const month = ref<string>("");
    const commissions = ref<CommissionIngestionStatusScreenItem[] | undefined>(
      []
    );
    const queryCommissions = ref<GetCommissions200Response>();

    // store performance based pay results for a certain month
    const performanceBasedPayResults = ref<PerformanceBasedPayResult[]>([]);

    // save selected PerformanceBasedPayResult data for display
    const selectedPerformanceBasedPayResult = ref<PerformanceBasedPayResult>();

    // collect contract reports that match a commissionId
    const contractReportMatches = ref<
      GetContractReportsCommissionMatching200ResponseResultsInner[]
    >([]);

    // collect values of the select box component for updateCommissionFormat
    const selectValues = ref<Record<string, string>>({});

    // store CSV data to display and use for updateCommissionFormat
    const csvData = ref<Array<Array<string | number>>>([]);
    const rawCsvData = ref<File>();

    // store data from getCommissionFormat
    const commissionFormat = ref<GetCommissionFormat200Response>();

    // store data from getCommissionInsuranceCompanyCalculationResults
    const commissionInsuranceCompanyCalculationResults =
      ref<GetCommissionInsuranceCompanyCalculationResults200Response>();

    // store data from getCommissionCalculationResults
    const commissionCalculationResults =
      ref<CommissionCalculationResultTable[]>();

    /**
     * collect commission and contract report data for matching
     */
    const selectedCommission = ref<SelectedCommissionType>();
    const selectedContractReport = ref<SelectedContractReportType>();

    // clear contractReportMatches
    const clearContractReportMatches = () => {
      contractReportMatches.value = [];
    };
    /**
     * Clears selected values
     */
    const clearSelected = async () => {
      selectedCommission.value = undefined;
      selectedContractReport.value = undefined;
    };

    /**
     * Set the current month to the state
     */
    const setCurrentMonth = async () => {
      const result = await getCurrentMonth();

      // check if the result is null
      month.value = result !== null ? result : "";
    };

    /**
     * Pre check for calculation of performance based pay
     */
    const preCalculationOfPerformanceBasedPayCheck = async () => {
      const res = await preCalculationCheck(month.value);
      return res;
    };

    /**
     * Get a commission status
     */
    const getCommissionStatus = async () => {
      // reset noDataFound to false
      noDataFound.value = false;

      // assign undefined if the result is null

      commissions.value = (await getStatus(month.value)) || undefined;

      // Set noDataFound to true if there is no data.
      if (commissions.value && commissions.value.length === 0) {
        noDataFound.value = true;
      }
    };

    /**
     * Calculate the performance based pay
     */
    const calculatePerformanceBasedPay = async () => {
      const res = await calculate(month.value);
      return res;
    };

    /**
     * Calculate the performance based pay
     */
    const getPerformanceBasedPayResults = async (staffId?: number) => {
      const response = await findBy(month.value, staffId);
      if (response) performanceBasedPayResults.value = response.results;
    };

    /**
     * Export the performance based pay
     */
    const exportPerformanceBasedPay = async () => {
      const res = await exportBy(month.value);
      return res;
    };

    /**
     * Import a commission CSV
     * @param file
     */
    const addCommissionCsv = async (
      ...args: Parameters<typeof importCommissionCsv>
    ) => {
      const res = await importCommissionCsv(...args);
      return res;
    };
    /**
     * delete a commission import
     */
    const deleteCommissionImport = async (
      ...args: Parameters<typeof deleteCommission>
    ) => {
      const res = await deleteCommission(...args);
      return res;
    };

    /**
     * Culculate a commission
     */
    const calculateCommission = async (commissionImportIds: number[]) => {
      const res = await calcCommission({
        commissionImportIds,
      });
      return res;
    };

    /**
     * Import a payroll file
     * @param file
     */
    const importPayrollFile = async (
      ...args: Parameters<typeof importPayrollCsv>
    ) => {
      const res = await importPayrollCsv(...args);
      return res;
    };

    /**
     * Get a commission format
     */
    const getCommissionFormat = mapFalsyToUndefinedAndCall(
      async (...args: Parameters<typeof get>) => {
        const res = await get(...args);

        // assign undefined if the result is null
        commissionFormat.value = (await get(...args)) || undefined;
      }
    );

    /**
     * Update a commission format
     */
    const updateCommissionFormat = async (
      insuranceCompanyId: number,
      consumptionTaxIncludeFlag: boolean,
      commissionCategory: CommissionCategory
    ) => {
      // Make sure that the csvData is not empty
      if (!csvData.value || csvData.value.length === 0) {
        throw new Error("CSV data is empty");
      }
      const convert = convertDataToPutCommissionFormatRequest(
        consumptionTaxIncludeFlag,
        csvData.value[0],
        selectValues.value
      );
      const res = await update(insuranceCompanyId, commissionCategory, convert);
      if (res) return res.status;
    };

    /**
     * Get a insuranceCompanyName
     */
    const getInsuranceCompanyInfo = async (insuranceCompanyId: any) => {
      const res = await getInsuranceCompany(insuranceCompanyId);
      return res;
    };

    /**
     * Get a commission status
     */
    // FIXME: return to normal after testing.

    const getCommissions = async (commissionImportId: number) => {
      // assign undefined if the result is null
      const response =
        (await getCommissionsQuery(commissionImportId)) || undefined;
      queryCommissions.value = response;
      return response;
    };

    /**
     * Get contract reports that match commissionId
     */
    const getContractReportMatches = async (commissionId: number) => {
      contractReportMatchesSendingRequest.value = true;
      const response = await findMatch(commissionId);
      contractReportMatchesSendingRequest.value = false;

      contractReportMatches.value = response?.results;
    };

    /**
     * Match a commission
     */
    const matchSecurityCommission = async (commissionImportId: number) => {
      const request = {
        commissionId: Number(selectedCommission.value?.commissionId),
        contractReportId: Number(
          selectedContractReport.value?.contractReportId
        ),
      };

      const res = await postSecuritiesCommissionMatching(request);

      if (res === null) return;
      const { status } = res;

      //  if the postSecuritiesCommissionMatching request returns a 200 status, refresh the commission table
      if (status === 200) {
        // filter out the matched commission
        queryCommissions.value.commissions =
          queryCommissions.value.commissions.filter(
            (commission) =>
              commission.commissionId !== selectedCommission.value?.commissionId
          );
        // set contractReportMatches back to default state
        contractReportMatches.value = [];
      }
      return res;
    };

    /**
     * Import a payroll file
     * @param file
     */
    const importSecuritiesFile = async (
      ...args: Parameters<typeof importSecuritiesCsv>
    ) => {
      const res = await importSecuritiesCsv(...args);
      return res;
    };

    /**
     * Get results for GetCommissionInsuranceCompanyCalculationResults
     */
    const getCommissionInsuranceCompanyCalculationResult = async (
      staffId: number
    ) => {
      commissionInsuranceCompanyCalculationResults.value =
        (await getCommissionInsuranceCompanyCalculationResults(
          staffId,
          month.value
        )) || undefined;
    };

    /**
     * Get commissionCalculationResults
     */
    const getCommissionCalculation = async (staffId: number, month: string) => {
      // reset noCommissionCalculationResultsFound to false
      noCommissionCalculationResultsFound.value = false;

      // assign undefined if the result is null
      const res =
        (await getCommissionCalculationResults(staffId, month, 1, 1000)) ||
        undefined;
      const convertedResults = ref();

      if (res && res.results.length > 0) {
        // Use map to convert each result in the array
        convertedResults.value = res.results.map((result) =>
          convertCommissionCalculationResults(result)
        );
      } else {
        convertedResults.value = undefined;
        noCommissionCalculationResultsFound.value = true;
      }

      commissionCalculationResults.value = convertedResults.value;
    };

    /**
     * Export the commission calculation
     */
    const exportCommissionCalculationResultsCsv = async (
      staffId: number,
      month: string
    ) => {
      const res = await exportCommissionCalculationResultsBy(staffId, month);
      return res;
    };

    return {
      sendingRequest,
      contractReportMatchesSendingRequest,
      clearSelected,
      month,
      selectValues,
      csvData,
      rawCsvData,
      commissions,
      commissionFormat,
      selectedCommission,
      selectedContractReport,
      setCurrentMonth,
      preCalculationOfPerformanceBasedPayCheck,
      getCommissionStatus,
      calculatePerformanceBasedPay,
      exportPerformanceBasedPay,
      deleteCommissionImport,
      calculateCommission,
      importPayrollFile,
      getCommissionFormat,
      updateCommissionFormat,
      getInsuranceCompanyInfo,
      addCommissionCsv,
      getCommissions,
      queryCommissions,
      matchSecurityCommission,
      getContractReportMatches,
      contractReportMatches,
      clearContractReportMatches,
      performanceBasedPayResults,
      getPerformanceBasedPayResults,
      importSecuritiesFile,
      selectedPerformanceBasedPayResult,
      getCommissionInsuranceCompanyCalculationResult,
      commissionInsuranceCompanyCalculationResults,
      getCommissionCalculation,
      commissionCalculationResults,
      exportCommissionCalculationResultsCsv,
      noDataFound,
      noCommissionCalculationResultsFound,
    };
  }
);

// match headers to data from select boxes, and create a put request body
export const convertDataToPutCommissionFormatRequest = (
  consumptionTaxIncludeFlag: boolean,
  headers: any,
  selectValues: any
) => {
  const matchedData: Record<string, string> = {};
  const selectKeys = Object.keys(selectValues);

  for (let i = 0; i < selectKeys.length; i++) {
    const key = selectKeys[i];
    const value = selectValues[key];

    if (value && headers[i]) {
      matchedData[value] = headers[i];
    }
  }

  const converted = {
    consumptionTaxIncludeFlag: consumptionTaxIncludeFlag,
    columnMapping: matchedData,
  };
  return removeUndefinedAndEmptyStringFromObject(converted);
};

export const convertCommissionCalculationResults = (
  result: GetCommissionCalculationResults200ResponseResultsInner
) => {
  let insured;
  let insuranceInfo;
  let boss;
  let otherStaff1;
  let otherStaff2;
  let otherStaff3;
  let market;

  const commissionImport = {
    insuranceCompanyName: result.commissionImport.insuranceCompanyName,
    commissionCategory: result.commissionImport.commissionCategory,
    commissionCategoryName: getValueFromEnum(
      "commissionCategory",
      result.commissionImport.commissionCategory
    ),
  };
  if (result.securities.insured) {
    insured = {
      fullName: result.securities.insured
        ? `${result.securities.insured?.lastName}${result.securities.insured?.firstName}`
        : "",
    };
  } else {
    insured = undefined;
  }
  if (result.securities.insuranceInfo) {
    insuranceInfo = {
      insuranceTypeName:
        result.securities.insuranceInfo?.insuranceTypeName ?? "",
      paymentMethod: result.securities.insuranceInfo?.paymentMethod ?? "",
      paymentMethodDisplay: result.securities.insuranceInfo?.paymentMethod
        ? getValueFromEnum(
            "paymentMethod",
            result.securities.insuranceInfo?.paymentMethod
          )
        : "",
    };
  } else {
    insuranceInfo = undefined;
  }
  const securities = {
    securitiesId: result.securities.securitiesId,
    policyNumber: result.securities.policyNumber,
    staffId: result.securities.staffId,
    insuranceCategory: result.securities.insuranceCategory,
    contractorAttribute: result.securities.contractorAttribute,
    firstPaymentMonth: result.securities.firstPaymentMonth,
    contractorName:
      result.securities.contractorAttribute === "individual"
        ? `${result.securities.contractorIndividual?.lastName}${result.securities.contractorIndividual?.firstName}`
        : result.securities.contractorCorporation?.corporationName,
    insuranceInfo,
    insured,
  };

  if (result.commission.boss) {
    boss = {
      staffNumber: result.commission.boss.staffNumber,
      fullName: `${result.commission.boss.lastName}${result.commission.boss.firstName}`,
      share: result.commission.boss.share,
      commission: result.commission.boss.commission,
      consumptionTax: result.commission.boss.consumptionTax,
      totalTax:
        result.commission.boss.commission +
        result.commission.boss.consumptionTax,
    };
  } else {
    boss = undefined;
  }
  if (result.commission.otherStaff1) {
    otherStaff1 = {
      staffNumber: result.commission.otherStaff1.staffNumber,
      fullName: `${result.commission.otherStaff1.lastName}${result.commission.otherStaff1.firstName}`,
      share: result.commission.otherStaff1.share,
      commission: result.commission.otherStaff1.commission,
      consumptionTax: result.commission.otherStaff1.consumptionTax,
      totalTax:
        result.commission.otherStaff1.commission +
        result.commission.otherStaff1.consumptionTax,
    };
  } else {
    otherStaff1 = undefined;
  }
  if (result.commission.otherStaff2) {
    otherStaff2 = {
      staffNumber: result.commission.otherStaff2.staffNumber,
      fullName: `${result.commission.otherStaff2.lastName}${result.commission.otherStaff2.firstName}`,
      share: result.commission.otherStaff2.share,
      commission: result.commission.otherStaff2.commission,
      consumptionTax: result.commission.otherStaff2.consumptionTax,
      totalTax:
        result.commission.otherStaff2.commission +
        result.commission.otherStaff2.consumptionTax,
    };
  } else {
    otherStaff2 = undefined;
  }
  if (result.commission.otherStaff3) {
    otherStaff3 = {
      staffNumber: result.commission.otherStaff3.staffNumber,
      fullName: `${result.commission.otherStaff3.lastName}${result.commission.otherStaff3.firstName}`,
      share: result.commission.otherStaff3.share,
      commission: result.commission.otherStaff3.commission,
      consumptionTax: result.commission.otherStaff3.consumptionTax,
      totalTax:
        result.commission.otherStaff3.commission +
        result.commission.otherStaff3.consumptionTax,
    };
  } else {
    otherStaff3 = undefined;
  }
  if (result.commission.market) {
    market = {
      marketName: result.commission.market.marketName,
      share: result.commission.market.share,
      commission: result.commission.market.commission,
      consumptionTax: result.commission.market.consumptionTax,
      totalTax:
        result.commission.market.commission +
        result.commission.market.consumptionTax,
    };
  } else {
    market = undefined;
  }
  const commission = {
    all: {
      commission: result.commission.all.commission,
      consumptionTax: result.commission.all.consumptionTax,
      totalTax:
        result.commission.all.commission + result.commission.all.consumptionTax,
    },
    myself: {
      staffNumber: result.commission.myself.staffNumber,
      fullName: `${result.commission.myself.lastName}${result.commission.myself.firstName}`,
      share: result.commission.myself.share ?? undefined,
      commission: result.commission.myself.commission ?? undefined,
      consumptionTax: result.commission.myself.consumptionTax ?? undefined,
      totalTax:
        result.commission.myself.commission &&
        result.commission.myself.consumptionTax
          ? result.commission.myself.commission +
            result.commission.myself.consumptionTax
          : undefined,
    },
    boss,
    otherStaff1,
    otherStaff2,
    otherStaff3,
    market,
  };

  const converted: CommissionCalculationResultTable = {
    commissionImport: commissionImport,
    securities: securities,
    commission: commission,
  };

  return removeUndefinedAndEmptyStringFromObject(converted);
};
