import type {
  GetContractReport200Response,
  PostContractReportRequest,
} from "@/api";
import { rest } from "msw";
import randomInteger from "random-int";
import { useStaffQueryService } from "@/services/use-staff-query-service";
import useInsuranceTypeService from "@/services/use-insurance-type-service";
import useInsuranceCompanyService from "@/services/use-insurance-company-service";

import {
  dummyContactReports,
  replace,
} from "@/mocks/ContractReport/dummy-contracts";

import { useEnvironmentVars } from "@/composables/use-environment-vars";
const { getBaseUrl, mode } = useEnvironmentVars();
const BASE_PATH = getBaseUrl(mode);

/**
 * Convert json shape from PostContractReportRequest to GetContractReports200Response
 * @param params PostContractReportRequest
 * @returns [
    GetContractReports200Response,
  ]
 */

export const convert = async (
  params: PostContractReportRequest,
  contractReportId?: number
): Promise<GetContractReport200Response> => {
  const staffData = await useStaffQueryService().findByCurrent(params.staffId);
  const insuranceTypeData = await useInsuranceTypeService().get(
    params.insuranceInfo.insuranceTypeId
  );
  const insuranceCompanyData = await useInsuranceCompanyService().get(
    params.insuranceInfo.insuranceCompanyId
  );

  const newContract = {
    contractReportId: contractReportId
      ? Number(contractReportId)
      : randomInteger(1000000, 9999999),
    insuranceCategory: params.insuranceCategory,
    contractorAttribute: params.contractorAttribute,
    contractReportOn: params.contractReportOn,
    contractorIndividual: {
      //契約者  個人
      firstName: params.contractorIndividual?.firstName, // @
      lastName: params.contractorIndividual?.lastName, // @
      firstNameKana: params.contractorIndividual?.firstNameKana, // @
      lastNameKana: params.contractorIndividual?.lastNameKana, // @
      dateOfBirth: params.contractorIndividual?.dateOfBirth, // @
      postCode: params.contractorIndividual?.postCode, // @
      address1: params.contractorIndividual?.address1, // @
      address2: params.contractorIndividual?.address2, // @
      address3: params.contractorIndividual?.address3, // @
      address4: params.contractorIndividual?.address4,
      address5: params.contractorIndividual?.address5,
      tel1: params.contractorIndividual?.tel1, // @
      tel2: params.contractorIndividual?.tel2,
      telLast: params.contractorIndividual?.tel1
        ? params.contractorIndividual?.tel1.slice(-4)
        : undefined,
    },

    contractorCorporation: {
      // 契約者 法人
      corporationName: params.contractorCorporation?.corporationName, // @ // 法人名
      postCode: params.contractorCorporation?.postCode, // @
      address1: params.contractorCorporation?.address1, // @
      address2: params.contractorCorporation?.address2, // @
      address3: params.contractorCorporation?.address3, // @
      address4: params.contractorCorporation?.address4,
      address5: params.contractorCorporation?.address5,
      tel1: params.contractorCorporation?.tel1, // @
      tel2: params.contractorCorporation?.tel2,
      corporationRepresentativeFirstName:
        params.contractorCorporation?.corporationRepresentativeFirstName, //代表者
      corporationRepresentativeLastName:
        params.contractorCorporation?.corporationRepresentativeLastName, //代表者
      corporationClosingMonth:
        params.contractorCorporation?.corporationClosingMonth, //決算月
      telLast: params.contractorCorporation?.tel1
        ? params.contractorCorporation?.tel1.slice(-4)
        : undefined,
    },
    insured: {
      // 被保険者
      firstName: params.insured?.firstName, // @
      lastName: params.insured?.lastName, // @
      firstNameKana: params.insured?.firstNameKana, // @
      lastNameKana: params.insured?.lastNameKana, // @
      dateOfBirth: params.insured?.dateOfBirth,
      potentialCustomerPersonId: undefined,
    },
    insuranceInfo: {
      // 保険情報
      insuranceCompanyId: params.insuranceInfo.insuranceCompanyId, // @ //保険会社＠
      insuranceCompanyName: insuranceCompanyData?.insuranceCompanyName ?? "",
      insuranceTypeId: params.insuranceInfo.insuranceTypeId, // @ // 保険種類＠
      insuranceTypeName: insuranceTypeData?.insuranceTypeName ?? "",
      insuranceFee: params.insuranceInfo.insuranceFee,
      paymentMethod: params.insuranceInfo.paymentMethod,
      deemedAnnualPayment: params.insuranceInfo.deemedAnnualPayment,
    },
    lifeInsuranceInfo: {
      contractDateOn: params.lifeInsuranceInfo?.contractDateOn,
    },
    nonLifeInsuranceInfo: {
      policyNumber: params.nonLifeInsuranceInfo?.policyNumber,
      applicationDateOn: params.nonLifeInsuranceInfo?.applicationDateOn,
      insuranceStartDateOn: params.nonLifeInsuranceInfo?.insuranceStartDateOn,
      insuranceEndDateOn: params.nonLifeInsuranceInfo?.insuranceEndDateOn,
      receiptDateOn: params.nonLifeInsuranceInfo?.receiptDateOn,
      returnDateOn: params.nonLifeInsuranceInfo?.returnDateOn,
      nonLifeInsuranceAccountingCategory:
        params.nonLifeInsuranceInfo?.nonLifeInsuranceAccountingCategory,
      nonLifeInsuranceDepositCategory:
        params.nonLifeInsuranceInfo?.nonLifeInsuranceDepositCategory,
    },
    commissionShare: {
      //手数料
      // 取分合計が100%にならないとエラー
      companyShare: params.commissionShare.companyShare, // 本社取分率
      myselfShare: params.commissionShare.myselfShare, //担当募集人 取分率
      bossId: params.commissionShare.bossId, // 所属長
      bossShare: params.commissionShare.bossShare, // 所属長 取分率
      otherStaffId1: params.commissionShare.otherStaffId1, //その他募集人１
      otherStaffShare1: params.commissionShare.otherStaffShare1, // 取分率
      otherStaffId2: params.commissionShare.otherStaffId2, //その他募集人2
      otherStaffShare2: params.commissionShare.otherStaffShare2, //取分率
      otherStaffId3: params.commissionShare.otherStaffId3, // その他募集人3
      otherStaffShare3: params.commissionShare.otherStaffShare3, // 取分率
      marketShare: params.commissionShare.marketShare, //マーケット 取分率
    },
    incentiveShare: {
      // インセンティブ
      // 取分合計が100%にならないとエラー
      companyShare: params.incentiveShare.companyShare, // 本社取分率
      myselfShare: params.incentiveShare.myselfShare, //担当募集人 取分率
      bossId: params.incentiveShare.bossId, // 所属長
      bossShare: params.incentiveShare.bossShare, // 所属長 取分率
      otherStaffId1: params.incentiveShare.otherStaffId1, //その他募集人１
      otherStaffShare1: params.incentiveShare.otherStaffShare1, // 取分率
      otherStaffId2: params.incentiveShare.otherStaffId2, //その他募集人2
      otherStaffShare2: params.incentiveShare.otherStaffShare2, //取分率
      otherStaffId3: params.incentiveShare.otherStaffId3, // その他募集人3
      otherStaffShare3: params.incentiveShare.otherStaffShare3, // 取分率
      marketShare: params.incentiveShare.marketShare, //マーケット 取分率
    },
    staff: {
      firstName: staffData.personal.firstName,
      lastName: staffData.personal.lastName,
      staffId: params.staffId,
      departmentName: staffData.departmentAffiliation.departmentName,
      departmentId: staffData.departmentAffiliation.departmentId,
    },
    staffId: params.staffId,
    potentialCustomerInfo: {
      potentialCustomerId: params.potentialCustomerInfo.potentialCustomerId,
      potentialCustomerType: params.potentialCustomerInfo.potentialCustomerType,
      supportUse: params.potentialCustomerInfo.supportUse,
      marketId: params.potentialCustomerInfo.marketId,
    },
  };
  return newContract;
};
export const handlers = [
  rest.post(`${BASE_PATH}/contractReport`, async (req, res, ctx) => {
    const newReport: PostContractReportRequest = await req.json();
    // 1. ensure params is valid
    const report = await convert(newReport);

    // // 2. add it to customers in the store
    dummyContactReports.push(report);

    console.log("new report: ", report);

    return res(ctx.status(201), ctx.text(String(report.contractReportId)));
  }),

  /**
   * ContractReport
   * GetContractReport
   */
  rest.get(`${BASE_PATH}/contractReport/:contractReportId`, (req, res, ctx) => {
    const id = req.params.contractReportId;
    const contract = dummyContactReports.find(
      (contract) => contract.contractReportId === Number(id)
    );
    console.log("GET request,", id, contract);
    return res(ctx.status(200), ctx.json(contract));
  }),

  /**
   * ContractReport
   * PutContractReport
   */

  rest.put(
    `${BASE_PATH}/contractReport/:contractReportId`,
    async (req, res, ctx) => {
      const id = req.params.contractReportId;
      const contract = await req.json();

      const updatedContract = await convert(contract, Number(id));
      const oldContract = dummyContactReports.find(
        (contract) => contract.contractReportId === Number(id)
      );

      replace(oldContract, updatedContract);

      console.log("update contracts", dummyContactReports);

      return res(ctx.status(204));
    }
  ),
];
