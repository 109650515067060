import axios, { type AxiosInstance } from "axios";
import { getBaseUrl } from "@/composables/use-environment-vars";

import { Auth } from "aws-amplify";
import { amplifyConfig } from "@/aws_config";
import { useErrorMessageStore } from "@/stores/ErrorMessage";
import _ from "lodash-es";

Auth.configure(amplifyConfig);

const axiosInstance = axios.create();
axiosInstance.defaults.baseURL = getBaseUrl(import.meta.env.VITE_ENV);

/**
 * Axios interceptor to add the Authorization header with the user token.
 * Set the request header `Autho rization` to `Baerer idtoken` using axios interceptor.
 */
axiosInstance.interceptors.request.use(async (config) => {
  // check if the user is already signed in
  let user;
  try {
    user = await Auth.currentAuthenticatedUser();
    console.log("session", user.signInUserSession);
  } catch (error) {
    console.log("error: ", error);
  }

  // set the Authorization header with the user token if the user is signed in
  // otherwise, the Authorization header will be empty and alert in the console that the user is not signed in
  if (user) {
    config.headers.Authorization = `Bearer ${user.signInUserSession.idToken.jwtToken}`;
  } else {
    console.log(
      "User is not signed in but can send a request because you are in development mode."
    );
  }

  return config;
});

/**
 * Axios interceptor to handle errors.
 */

interface BackendApiErrorResponseBody {
  message: string;
}

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { setErrorMessage } = useErrorMessageStore();

    if (error.response) {
      const status = error.response.status;
      const data: BackendApiErrorResponseBody = error.response.data;
      const headers = error.response.headers;

      // IF error.response.message is empty, bind altanative error message to errorMessage
      const errorMessage =
        data && data.message
          ? data.message
          : "システムエラーが発生しました。再度処理をやり直してください。エラーが続く場合は情報システム部に連絡ください。";

      // Save the error message to the store
            setErrorMessage(errorMessage);

      switch (true) {
        case status === 400:
          console.error("Bad Request", {
            data,
            headers,
            errorMessage,
          });
          break;
        case status === 401:
          console.error("Unauthorized", {
            data,
            headers,
            errorMessage,
          });
          break;
        case status === 404:
          return Promise.reject(error);
          break;
        // Specific codes handled above
        case status >= 400 && status < 500:
          console.error(`Client Error with status code ${status}`, {
            data,
            headers,
            errorMessage,
          });
          break;
        case status >= 500 && status < 600:
          console.error(`Server Error with status code ${status}`, {
            data,
            headers,
            errorMessage,
          });
          break;
        default:
          console.error(`Unexpected status code ${status}`, {
            data,
            headers,
            errorMessage,
          });
          break;
      }

      // Handle the error here, but suppress propagation.
    } else if (error.request) {
      console.error("No response received from the server", error.request);
    } else {
      console.error("Error", error.message);
    }

    // If you want not to propagate the error to the calling code
    // return null;
    throw error;
  }
);

/**
 * Creates a deep copy of the default Axios instance and sets its default configuration.
 *
 * @param {Record<string, any>} defaultConfig - The default configuration to set on the copied Axios instance.
 * @returns {AxiosInstance} The copied Axios instance with the new default configuration.
 *
 * @example
 * const defaultConfig = { timeout: 1000 };
 * const copiedAxiosInstance = deepCopyWith(defaultConfig);
 */

export const deepCopyWith = (defaultConfig: Record<string, any>) => {
  // Deep copy axiosInstance to avoid unexpected behavior, using  _.cloneDeep() from lodash.
  const axiosInstanceCopy = _.cloneDeep(axiosInstance);

  // Set each propery to original.defaults from defaultConfig
  Object.keys(defaultConfig).forEach((key) => {
    const typedKey = key as keyof AxiosInstance["defaults"];
    axiosInstanceCopy.defaults[typedKey] = defaultConfig[typedKey];
  });

  return axiosInstanceCopy;
};
export default axiosInstance;
