import { rest } from "msw";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
const { getBaseUrl, mode } = useEnvironmentVars();
const BASE_PATH = getBaseUrl(mode);
import {
  restructuredDummyContactReports,
  contractReportCommissionMatchingResults,
} from "@/mocks/ContractReport/dummy-contracts";

/**
 * Converts a string to a boolean value.
 * @param str - The string to convert.
 * @returns The boolean value.
 */
function stringToBoolean(str: string): boolean {
  return str === "true";
}

export const handlers = [
  /**
   * ContractReportQuery
   * GetContractReports
   */
  rest.get(`${BASE_PATH}/contractReport`, async (req, res, ctx) => {
    // If this environment is not `test`
    if (import.meta.env.VITE_ENV !== "test") {
      if (Math.random() < 0.05) {
        // Return 500 error 95% of the time except for test environment
        return res(
          ctx.status(500),
          ctx.json({
            message: "Internal Server Error, but this is created intentionally",
          })
        );
      }
    }

    const authorizedFilterFlag = req.url.searchParams.get(
      "authorizedFilterFlag"
    );

    const attribute = req.url.searchParams.get("contractorAttribute");
    const keyword = req.url.searchParams.get("keyword");
    const insuranceCategory = req.url.searchParams.get("insuranceCategory");
    const potentialCustomerId = req.url.searchParams.get("potentialCustomerId");
    const contractReportId = req.url.searchParams.get("contractReportId");
    const staffId = req.url.searchParams.get("staffId");
    const insuranceCompanyId = req.url.searchParams.get("insuranceCompanyId");
    const departmentId = req.url.searchParams.get("departmentId");
    const policyNumber = req.url.searchParams.get("policyNumber");

    // marketId, contractReportOnFrom, and contractReportOnFrom don't exist in
    // API responses, so right now it isn't used to filter.

    let filteredContractReports = await restructuredDummyContactReports;

    filteredContractReports = filteredContractReports.filter(
      (report) => report.contractorAttribute == attribute
    );

    // NOTE: Intentinally this mock API doesn't handle `authorizedFilterFlag` from query params.

    // if (authorizedFilterFlag) {
    //   // return an empty array if authorizationFlag is true
    //   if (stringToBoolean(authorizedFilterFlag) == true) {
    //     return res(ctx.status(200), ctx.json({ results: [] }));
    //   }
    // }

    if (insuranceCategory) {
      filteredContractReports = filteredContractReports.filter(
        (report) => report.insuranceCategory == insuranceCategory
      );
    }

    if (keyword) {
      filteredContractReports = filteredContractReports?.filter((report) =>
        `${report.contractorIndividual?.firstName} 
        ${report.contractorIndividual?.lastName.toLowerCase()}
        ${report.contractorIndividual?.lastNameKana.toLowerCase()} 
        ${report.contractorIndividual?.firstNameKana.toLowerCase()}  
        ${report.insured?.firstName.toLowerCase()} 
        ${report.insured?.lastName.toLowerCase()}
        ${report.insured?.lastNameKana.toLowerCase()} 
        ${report.insured?.firstNameKana.toLowerCase()}  
        }`.includes(keyword as string)
      );
    }

    // NOTE: Since the response does not contain potentialCustomerId, there is no filtering effect.
    if (potentialCustomerId) {
      filteredContractReports = filteredContractReports?.filter((report) => {
        return report?.potentialCustomerId == Number(potentialCustomerId);
      });
    }

    if (contractReportId) {
      filteredContractReports = filteredContractReports?.filter((report) => {
        return report?.contractReportId == Number(contractReportId);
      });
    }

    if (staffId) {
      filteredContractReports = filteredContractReports?.filter((report) => {
        return report?.staff.staffId == Number(staffId);
      });
    }

    if (insuranceCompanyId) {
      filteredContractReports = filteredContractReports?.filter((report) => {
        return (
          report?.insuranceInfo.insuranceCompanyId == Number(insuranceCompanyId)
        );
      });
    }

    if (departmentId) {
      filteredContractReports = filteredContractReports?.filter((report) => {
        return report?.staff.departmentId == Number(departmentId);
      });
    }

    if (policyNumber) {
      filteredContractReports = filteredContractReports?.filter((report) => {
        return report.nonLifeInsuranceInfo?.policyNumber == policyNumber;
      });
    }

    const response = {
      results: filteredContractReports,
      pagination: {
        totalCount: 0,
        currentPage: 0,
        lastPage: 0,
      },
    };
    return res(ctx.status(200), ctx.json(response));
  }),

  /**
   * ContractReportQuery
   * GetContractReportsCommissionMatching
   */
  rest.get(
    `${BASE_PATH}/contractReport/:commissionId/commissionMatching`,
    (req, res, ctx) => {
      const commissionId = req.params.commissionId;

      const record = contractReportCommissionMatchingResults.find(
        (item) => item.commissionId === Number(commissionId)
      );

      if (record) {
        const { ...recordWithoutId } = record;

        const response = {
          results: [recordWithoutId],
          pagination: {
            totalCount: 0,
            currentPage: 0,
            lastPage: 0,
          },
        };
        return res(ctx.status(200), ctx.json(response));
      } else {
        const response = {
          results: [],
          pagination: {
            totalCount: 0,
            currentPage: 0,
            lastPage: 0,
          },
        };
        return res(ctx.status(200), ctx.json(response));
      }
    }
  ),

  /**
   * ContractReportQuery
   * GetContractReportLifeInsuranceCsv
   */
  rest.get(`${BASE_PATH}/contractReport/lifeInsurance/csv`, (req, res, ctx) => {
    const contractorAttribute = req.url.searchParams.get("contractorAttribute");
    const keyword = req.url.searchParams.get("keyword");
    const contractReportId = req.url.searchParams.get("contractReportId");
    const staffId = req.url.searchParams.get("staffId");
    const departmentId = req.url.searchParams.get("departmentId");
    const insuranceCompanyId = req.url.searchParams.get("insuranceCompanyId");
    const marketId = req.url.searchParams.get("marketId");
    const contractReportOnFrom = req.url.searchParams.get(
      "contractReportOnFrom"
    );
    const contractReportOnTo = req.url.searchParams.get("contractReportOnTo");

    // return a tantative CSV file
    const csvData = `"header1","header2","header3"
"row1col1","row1col2","row1col3"
"row2col1","row2col2","③㈱髙"`;

    return res(
      ctx.status(200),
      ctx.set("Content-Type", "text/csv"),
      ctx.set("Content-Disposition", `attachment;`),
      ctx.body(csvData)
    );
  }),

  /**
   * ContractReportQuery
   * GetContractReportNonLifeInsuranceCsv
   */
  rest.get(
    `${BASE_PATH}/contractReport/nonLifeInsurance/csv`,
    (req, res, ctx) => {
      const contractorAttribute = req.url.searchParams.get(
        "contractorAttribute"
      );
      const keyword = req.url.searchParams.get("keyword");
      const contractReportId = req.url.searchParams.get("contractReportId");
      const staffId = req.url.searchParams.get("staffId");
      const departmentId = req.url.searchParams.get("departmentId");
      const policyNumber = req.url.searchParams.get("policyNumber");
      const insuranceCompanyId = req.url.searchParams.get("insuranceCompanyId");
      const marketId = req.url.searchParams.get("marketId");
      const contractReportOnFrom = req.url.searchParams.get(
        "contractReportOnFrom"
      );
      const contractReportOnTo = req.url.searchParams.get("contractReportOnTo");

      // return a tantative CSV file
      const csvData = `"header1","header2","header3"
"row1col1","row1col2","row1col3"
"row2col1","row2col2","③㈱髙"`;

      return res(
        ctx.status(200),
        ctx.set("Content-Type", "text/csv"),
        ctx.set("Content-Disposition", `attachment;`),
        ctx.body(csvData)
      );
    }
  ),
];
